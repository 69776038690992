import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Animated from './animated'
import facebookImage from '../images/og-facebook.png'
import twitterImage from '../images/og-twitter.png'
import './layout.css'

const Layout = ({ children }) => (
    <StaticQuery
        query={graphql`
            query SiteTitleQuery {
                site {
                    siteMetadata {
                        title
                        description
                        keywords
                    }
                }
            }
        `}
        render={data => (
            <Fragment>
                <Helmet
                    title={data.site.siteMetadata.title}
                    meta={[
                        { name: 'description', content: data.site.siteMetadata.description },
                        { name: 'keywords', content: data.site.siteMetadata.keywords.join(', ') },
                        { name: 'og:image', content: facebookImage },
                        { name: 'twitter:card', content: 'summary_large_image' },
                        { name: 'twitter:site', content: '@globobeet' },
                        { name: 'twitter:title', content: data.site.siteMetadata.title },
                        {
                            name: 'twitter:description',
                            content: data.site.siteMetadata.description,
                        },
                        { name: 'twitter:image', content: twitterImage },
                    ]}
                >
                    <html lang="en" class="h-full" />
                    <body className="font-sans bg-black min-h-full flex" />
                    <link rel="stylesheet" href="https://use.typekit.net/hhh7oqm.css" />
                </Helmet>

                <Animated delay={250}>
                    <span className="bg bg-texture z-20 fixed w-full h-full pin-l pin-t opacity-40" />
                    <span className="bg bg-gradient z-10 fixed w-full h-full pin-l pin-t" />
                    <span className="bg-white z-0 fixed w-full h-full pin-l pin-t" />
                </Animated>

                <div className="bg-transparent relative z-30 min-h-full flex flex-col justify-center px-5 pt-8 pb-12 lg:pt-16 lg:pb-20">
                    <div className="flex-no-shrink md:mx-12 lg:mx-24 max-w-content">
                        <div>{children}</div>
                    </div>
                </div>
            </Fragment>
        )}
    />
)

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
