import React, { Component } from 'react'
import { CSSTransition } from 'react-transition-group'

export default class Animated extends Component {
    state = {
        show: false,
    }

    componentDidMount() {
        const { delay = 1 } = this.props
        setTimeout(() => this.setState({ show: true }), delay)
    }

    render() {
        const { children, animation = 'fade' } = this.props
        const { show } = this.state

        return (
            <CSSTransition classNames={animation} in={show} duration={1000} appear>
                <div className={show ? 'visible' : 'invisible'}>{children}</div>
            </CSSTransition>
        )
    }
}
